import data from '../datas/resume';
import items from '../datas/items';
import skills from '../datas/languages';
import steps from '../datas/parcours';
import portfolio from '../datas/portfolio';

const initialState = {
    resume: data,
    menuItems: items,
    skills: skills,
    steps: steps,
    portfolio: portfolio,
};

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        default: return state;
    }
};

export default reducer;