import './style.scss';
import cv from '../../Assets/CV_Timothe_Bequet.pdf';

function DownloadButton() { 
    return (
        <div className='button__container'>
            <div className='button__download' onClick={() => {window.open(cv, '_blank');}}>
                <p>Téléchargez-moi en PDF !</p>
                <div className="button__animation">
                    <lottie-player src="https://lottie.host/fe18e7d6-23ae-40fb-b2c9-d2355991d119/80IWXsvZ2W.json" background="transparent" speed="1" style={{width: '40px', height: '40px', color: 'white'}} loop autoplay></lottie-player>
                </div>
            </div>
        </div>
    )
};

export default DownloadButton; 