const items = [
    {
        text: 'A Propos',
        image: 'dev-web.jpg',
        link: '/',
    },
    {
        text: 'Compétences',
        image: 'skills.jpg',
        link: '/skills',
    },
    {
        text: 'Parcours',
        image: 'steps.jpg',
        link: '/parcours',
    },
    {
        text: 'Portfolio',
        image: 'portfolio.jpg',
        link: '/portfolio',
    },
    {
        text: 'Contact',
        image: 'contact.jpg',
        link: '/contact',
    },                
];

export default items;
