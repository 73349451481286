import './style.scss';
import { useState } from 'react';

function Contact() {
    const [status, setStatus] = useState("Envoyer !");
    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Envoi en cours...");
        const { name, email, message } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            message: message.value,
        };
        let response = await fetch('https://cv.timothe-bequet.fr:3001/contact', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        });
        setStatus("Envoyé");
        let result = await response.json();
        alert(result.status);
    };
    return (
        <div className="contact principal">
            <div className="section__container contact__container">
                <div className="contact__header">
                    <h2 className="contact__header-title">Contactez-moi !</h2>
                    <div className="contact__header-animation">
                        <lottie-player src="https://assets1.lottiefiles.com/packages/lf20_in4cufsz.json"  background="transparent"  speed="1"  style={{width: '300px', height: '300px'}} loop  autoplay></lottie-player>
                    </div> 
                    <div className="contact__header-animation contact__header-mobile">
                        <div className="contact__header-mobile-item"><i className="bi bi-geo-alt"></i><p> 31200 Toulouse</p></div>
                        <div className="contact__header-mobile-item"><i className="bi bi-telephone"></i><p> 06.30.92.21.21</p></div>
                        <div className="contact__header-mobile-item"><i className="bi bi-envelope-at"></i><p><a href="mailto:tim.bequet@gmail.com" target="_blank" rel="noreferrer"> tim.bequet@gmail.com</a></p></div>
                        <div className="contact__header-mobile-item"><i className="bi bi-linkedin"></i><p><a href="https://www.linkedin.com/in/timothe-bequet/" target="_blank" rel="noreferrer"> Profil LinkedIn</a></p></div>
                    </div>
                </div>
                <div className="contact__form-container">
                    <form className="contact__form" onSubmit={handleSubmit}>
                        <input className="contact__form-input text" type="text" id="name" placeholder="Votre nom / prénom" required></input>
                        <input className="contact__form-input text" type="email" id="email" placeholder="Votre email" required></input>
                        <textarea className="contact__form-input" rows="10" id="message" placeholder="Et enfin, votre message ici" required></textarea>
                        <button className="contact__form-button" type="submit"><i className="bi bi-send"></i>   {status}</button>
                    </form>
                </div> 
            </div>
        </div>
    )
};

export default Contact;