import html from './../Assets/images/html-5.svg';
import css from './../Assets/images/css-3.svg';
import js from './../Assets/images/js.svg';
import php from './../Assets/images/php.svg';
import sql from './../Assets/images/sql-database.svg';
import cpp from './../Assets/images/cpp.svg';
import react from './../Assets/images/react.svg';
import angular from './../Assets/images/angular.svg';
import typescript from './../Assets/images/typescript.svg';
import vuejs from'./../Assets/images/VueJs.svg';
import java from './../Assets/images/Java.svg';

const languages = [
    {
        type: 'Angular',
        image: angular,
        complement: {}
    },
    {
        type: 'VueJs',
        image: vuejs,
        complement: {}
    },
    {
        type: 'React',
        image: react,
        complement: {
            title: 'Frameworks',
            objects: ['Redux']
        }
    },
    {
        type: 'HTML',
        image: html,
        complement: {}
    },
    {
        type: 'CSS',
        image: css,
        complement: {
            title: "Frameworks",
            objects: ['Boostrap 5', 'SCSS']
        }
    },
    {
        type: 'JavaScript',
        image: js,
        complement: {}
    },
    {
        type: 'TypeScript',
        image: typescript,
        complement: {}
    },
    {
        type: 'Java',
        image: java,
        complement: {
            title: 'Frameworks',
            objects: ['SpringBoot']
        }
    },
    {
        type: 'PHP',
        image: php,
        complement: {}
    },
    {
        type: 'SQL',
        image: sql,
        complement: {
            title: 'Moteurs de BDD',
            objects: ['FireBird', 'MySQL']
        }
    },
    {
        type: 'C++',
        image: cpp,
        complement: {
            title: 'Librairies',
            objects: ['DevExpress', 'VCL']
        }
    },
];

export default languages;