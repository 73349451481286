import slider from './../Assets/images/portfolio/zoom_slider1.jpg';
import sliderGif from './../Assets/images/portfolio/zoom_slider_gif.gif';
import menu from './../Assets/images/portfolio/3d_menu.jpg';
import menuGif from './../Assets/images/portfolio/3d_menu_gif.gif';
import gabrielle from './../Assets/images/portfolio/integ-gabrielle.jpg';
import gabrielleResponsive from './../Assets/images/portfolio/integ-gabrielle-responsive.jpg';
import qrcode from './../Assets/images/portfolio/qr-code-component.jpg';
import rating from './../Assets/images/portfolio/rating.jpg';
import ratingGif from './../Assets/images/portfolio/rating_gif.gif';
import faq from './../Assets/images/portfolio/faq-accordion-desktop.jpg';
import faqMobile from './../Assets/images/portfolio/faq-accordion-mobile.jpg';
import faqGif from './../Assets/images/portfolio/faq-accordion-gif.gif';
import socialIcons from './../Assets/images/portfolio/icons-hovered.jpg';
import socialIconsGif from './../Assets/images/portfolio/social-icons.gif';

const portfolio = {
    composants: [
        {
            imageClass: 'img-social-icons',
            imagePath: socialIcons,
            target: '#socialIconsComponent',
            alt: 'Social Icons',
            modal: {
                id: 'socialIconsComponent',
                title: 'Social Icons',
                languages: 'HTML - CSS',
                content: ['Des boutons d\'accès aux réseaux sociaux.', 'Effet d\'agrandissement et de coloration au survol de la souris sur les éléments.'],
                sources: [
                    {
                        title: 'Page GitHub',
                        href: 'https://github.com/TimotheBequet/Social-Icons',
                    },
                    {
                        title: 'Demo sur GitHub Pages',
                        href: 'https://timothebequet.github.io/Social-Icons/',
                    }
                ],
                image: socialIconsGif,
                imageHover: null,
            },
        },        
        {
            imageClass: 'img-slider',
            imagePath: slider,
            target: '#sliderComponent',
            alt: 'Composant Slider',
            modal: {
                id: 'sliderComponent',
                title: 'Zoom Slider',
                languages: 'HTML - CSS - JavaScript',
                content: ['Un slider avec effet de zoom entre les slides.', 'Gestion d\'un menu, dont les items mènent aux pages et se sélectionnent/désélectionnent lors du passage d\'une slide à l\'autre.'],
                sources: [
                    {
                        title: 'Page CodePen.io (ne prend pas en compte Boostrap)',
                        href: 'https://codepen.io/TimotheBequet/pen/eYjgxyR',
                    },
                    {
                        title: 'Page GitHub',
                        href: 'https://github.com/TimotheBequet/ZoomSlider',
                    }
                ],
                image: sliderGif,
                imageHover: null,
            },
        },
        {
            imageClass: 'img-3dmenu',
            imagePath: menu,
            target: '#menuComponent',
            alt: 'Composant Menu',
            modal: {
                id: 'menuComponent',
                title: '3D Menu',
                languages: 'HTML - CSS',
                content: ['Un menu avec effet 3D sur les items.', 'Effet de recouvrement lors du passage de la souris sur les éléments du menu.'],
                sources: [
                    {
                        title: 'Page CodePen.io',
                        href: 'https://codepen.io/TimotheBequet/pen/BaPqzMX',
                    },
                    {
                        title: 'Page GitHub',
                        href: 'https://github.com/TimotheBequet/3DMenu',
                    }
                ],
                image: menuGif,
                imageHover: null,
            },            
        },
    ],
    integrations: [
        {
            imageClass: 'portfolio-integ',
            imagePath: faq,
            target: '#integFaq',
            alt: 'FAQ Accordion',
            modal: {
                id: 'integFaq',
                title: 'FAQ Accordion',
                languages: 'HTML - CSS - JavaScript',
                content: ['Intégration d\'un composant de FAQ interactif à partir d\'une maquette donnée.', 'Responsive selon la largeur de la fenêtre (passer la souris sur l\'image pour voir la version responsive).'],
                sources: [
                    {
                        title: 'Code sur Frontend Mentor',
                        href: 'https://www.frontendmentor.io/solutions/faq-accordion-card-with-vanillajs-BQ0wcf_ik5',
                    },
                ],
                image: faqGif,
                imageHover: faqMobile,
            },
        },        
        {
            imageClass: 'portfolio-integ',
            imagePath: rating,
            target: '#integRating',
            alt: 'Intégration Rating Component',
            modal: {
                id: 'integRating',
                title: 'Interactive Rating Component',
                languages: 'HTML - CSS - JavaScript',
                content: ['Intégration d\'un composant de notation interactif à partir d\'une maquette donnée.'],
                sources: [
                    {
                        title: 'Code sur Frontend Mentor',
                        href: 'https://www.frontendmentor.io/solutions/mobilefirst-interactive-rating-component-with-flexbox-and-javascript-yEQiVAO4MP',
                    },
                ],
                image: ratingGif,
                imageHover: null,
            },
        },        
        {
            imageClass: 'portfolio-integ',
            imagePath: gabrielle,
            target: '#integGabrielle',
            alt: 'Intégration parfum Gabrielle',
            modal: {
                id: 'integGabrielle',
                title: 'Publicité Parfum Gabrielle',
                languages: 'HTML - CSS',
                content: ['Intégration d\'une pub pour un parfum, à partir d\'une maquette donnée.', 'Responsive selon la largeur de la fenêtre (passer la souris sur l\'image pour voir la version responsive).'],
                sources: [
                    {
                        title: 'Code sur Frontend Mentor',
                        href: 'https://www.frontendmentor.io/solutions/product-preview-card-component-J0S-xVhWy2',
                    },
                ],
                image: gabrielle,
                imageHover: gabrielleResponsive,
            }, 
        },
        {
            imageClass: 'portfolio-integ',
            imagePath: qrcode,
            target: '#integQrCode',
            alt: 'Intégration QR Code',
            modal: {
                id: 'integQrCode',
                title: 'QR Code',
                languages: 'HTML - CSS',
                content: ['Intégration d\'un QrCode, à partir d\'une maquette donnée.'],
                sources: [
                    {
                        title: 'Code sur Frontend Mentor',
                        href: 'https://www.frontendmentor.io/solutions/qr-code-component-7RikvtH9Ya',
                    },
                ],
                image: qrcode,
                imageHover: null,
            },
        },        
    ],
};

export default portfolio;