import { Routes, Route } from 'react-router-dom';
import '../../reset.css';
import './App.css';
import Menu from '../Menu/Menu';
import Home from '../Home/Home';
import Skills from '../Skills/Skills';
import Parcours from '../Parcours/Parcours';
import Portfolio from '../Portfolio/Portfolio';
import ScrollToTop from './ScrollToTop';
import Contact from '../Contact/Contact';
import DownloadButton from '../Download/DownloadButton';

function App() {
  return (
    <div className="App" style={{width: '100%'}}>
      <Menu />
      <main>  
        <DownloadButton />
        <ScrollToTop />    
        <Routes>          
          <Route path="/" element={<Home />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/parcours" element={<Parcours />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
