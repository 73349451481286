import PropTypes from 'prop-types';
import './style.scss';

function Languages({ skillArray }) {
    return (
        <div className={`container-card ${skillArray.complement.title !== undefined ? 'flip-card' : ''}`} data-tooltip={`${skillArray.type}`}>
            <div className={`skills__corps-container-lang ${skillArray.complement.title !== undefined ? 'flip' : ''}`}>
                <div className="card">
                    <img src={skillArray.image} alt={skillArray.image} />
                </div>
                {
                    skillArray.complement.title !== undefined ?
                    <div className="card card-back">
                        <h4 className='card-back-title'>{skillArray.complement.title}</h4>
                        {
                            skillArray.complement.objects.map((libs) => (
                                <p className="card-back-libs" key={libs}>{libs}</p>
                            ))
                        }
                    </div>
                    : undefined
                }
            </div>
        </div>
    )
};

Languages.propTypes = {
    skillArray: PropTypes.object.isRequired,
};

export default Languages;