import { useSelector } from "react-redux";
import "./style.scss";

function ResumeDetail() {
    const data = useSelector((state) => state.resume); 
    
    const handleMouseEnter = (event) => {
        let imageElement = null;
        if (event.currentTarget.classList.contains('phone')) {
            imageElement = document.querySelector('.bi-telephone');
        } else {
            imageElement = document.querySelector('.bi-envelope-at');
        }
        imageElement.classList.add('shake');
    };

    const handleMouseOut = (event) => {
        let imageElement = null;
        if (event.currentTarget.classList.contains('phone'))
            imageElement = document.querySelector('.bi-telephone');
        else
            imageElement = document.querySelector('.bi-envelope-at');        
        imageElement.classList.remove('shake');        
    };

    return (
        <div className="menu__resume">
            {
            data.map((item) => (
                <div key={item.text}className="menu__resume-detail-container">
                    {item.img !== '' && <i className={item.img}></i>}
                    <p 
                        className={`menu__resume-detail ${item.type === 'phone' ? 'phone hover-shake' : ''}`}
                        onMouseEnter={item.type === 'phone' ? handleMouseEnter : undefined}
                        onMouseOut={item.type === 'phone' ? handleMouseOut : undefined}
                    >
                    {
                        item.type === 'mail' ?
                        <a
                            className="mail hover-shake"
                            href="mailto:tim.bequet@gmail.com"
                            onMouseEnter={item.type === 'mail' ? handleMouseEnter : undefined}
                            onMouseOut={item.type === 'mail' ? handleMouseOut : undefined}
                        >
                            {item.text}
                        </a> : item.text
                    }
                    </p>
                </div>
            ))
        }
        </div>
    );
};

export default ResumeDetail;
