import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import "./style.scss";

function Item() {
    const menuItems = useSelector((state) => state.menuItems);
    return (
        <div>
            <ul className="menu__list">
            {
                menuItems.map((item) => (
                    <NavLink
                        key={item.text}
                        className={({ isActive }) => (isActive ? 'menu__list-item active' : 'menu__list-item')}
                        to={item.link}
                    >
                        {item.text}
                    </NavLink>
                ))
            }
            </ul>
        </div>
    );
};

export default Item;
