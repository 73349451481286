import './style.scss';

function ParcoursItem({ stepArray }) {
    return (
        <li className={`parcours__timeline-item ${stepArray.study ? '' : 'reverse'}`}>
            <div className={`parcours__timeline-item-badge ${stepArray.study ? 'study' : 'pro'}`}>
                <i className={`bi ${stepArray.study ? 'bi-mortarboard-fill' : 'bi-briefcase-fill'}`}></i>
            </div>
            <div className={`${stepArray.study ? 'parcours__timeline-item-panel' : 'parcours__timeline-item-panel-reverse'}`}>
                <div className='parcours__timeline-panel'>
                    <div className="parcours__timeline-panel-heading">
                        <p className="parcours__timeline-panel-date"><i className="bi bi-clock-history"></i> {stepArray.date}</p>
                        <h3 className="parcours__timeline-panel-poste">
                            {stepArray.poste} {stepArray.posteSup !== '' ? <><br /><span>{stepArray.posteSup}</span></> : undefined}
                        </h3>
                        <h4 className={`parcours__timeline-panel-entreprise ${stepArray.study ? 'study' : 'pro'}`}>
                        {
                            stepArray.linkEntreprise !== '' ? 
                            <a className={`${stepArray.study ? 'study' : 'pro'}`} href={stepArray.linkEntreprise} target="_blank" rel="noopener noreferrer">
                                {stepArray.entreprise}
                                <i className="bi bi-link-45deg"></i>
                            </a> : stepArray.entreprise
                        }                                                                                                             
                        </h4>
                        {
                            stepArray.spe !== '' ?
                            <h5 className="parcours__timeline-panel-spe">{stepArray.spe}</h5>
                            : undefined
                        }
                    </div>
                    {                        
                        stepArray.content.length === 0 ? undefined :
                        <div className="parcours__timeline-panel-content">
                        {
                            stepArray.content.map((content) => (
                            <p key={content}>{content}</p>
                            ))
                        }
                        </div>
                    }
                </div>
            </div>
        </li>
    )
};

export default ParcoursItem;