import { useSelector } from 'react-redux';
import ParcoursItem from './ParcoursItem/ParcoursItem';
import './style.scss';

function Parcours() {
    const steps = useSelector((state) => state.steps);
    return (
        <div className='parcours principal'>
            <div className='section__container'>
                <h2 className="parcours__title">Parcours</h2>
                <div className="parcours__animation">
                    <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_hxart9lz.json"  background="transparent"  speed="1"  style={{width: '300px', height: '300px'}} loop  autoplay></lottie-player>
                </div>                 
                <ul className="parcours__timeline">
                {
                    steps.map((step) => (
                        <ParcoursItem key={step.date} stepArray={step} />
                    ))
                }
                </ul>
            </div>
        </div>
    )
};

export default Parcours;