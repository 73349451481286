// import Page from '../Page/Page';
import './style.scss';

function Home() { 
    return (        
        <div className="home principal">
            <div className="section__container home__container">
                <h2 className="home__title">Hello World !</h2>
                <div className="home__card home__border-left">
                    <p className="home__text">
                    Je m'appelle Timothé Bequet, j'ai 38 ans et je suis <span className="home__important">Développeur Web, spécialisé Front-End</span>.<br/><br/>
                    Suite à une envie de changement et afin de donner un second souffle à ma carrière, j'ai décidé de quitter le poste <strong>d'Analyste Développeur C++</strong> que j'occupais depuis 10 ans pour me réorienter vers le développement web, via <a className="home__link" href="https://oclock.io/formations/developpeur-web" target="_blank" rel="noopener noreferrer">une formation de 6 mois auprès de l'école O'Clock <i className="bi bi-link-45deg"></i></a><br/><br/>  
                    Basé à <span className="home__important">Toulouse</span>, je souhaite mettre à disposition mes compétences au sein d'une équipe pour le développement de vos sites et applications web.
                    </p>
                    <div className="home__animation">
                        <lottie-player src="https://assets7.lottiefiles.com/packages/lf20_V9t630.json"  background="transparent"  speed="1"  style={{width: '300px', height: '300px'}} loop  autoplay></lottie-player>
                    </div>                      
                </div>
                <div className="home__card home__border-right">
                    <div className="home__animation">
                        <lottie-player src="https://assets7.lottiefiles.com/packages/lf20_ukGJVpilSb.json"  background="transparent"  speed="1"  style={{width: '300px', height: '300px'}} loop  autoplay></lottie-player>
                    </div>
                    <p className="home__text">
                        Cette formation m'a permis de m'ouvrir aux métiers du Web, et particulièrement côté <span className="home__important">Front-End</span> grâce à une spécialisation sur <span className="home__important">ReactJS</span>.<br/><br/>
                        Mon expérience en programmation orientée objet ainsi que les nombreuses heures de cours en <span className="home__important">PHP</span> et <span className="home__important">SQL</span> me confèrent également une aisance en développement <span className="home__important">Back-End</span>.
                    </p>
                </div>
                <div className="home__card home__border-left home__border-top">
                    <p className="home__text">
                        Ma faculté d'adaptation, mon envie perpétuelle de m'améliorer ainsi que ma bonne humeur (si si, c'est important !) feront, j'en suis sûr, la différence au sein de votre entreprise.<br/><br/>
                        Découvrez en parcourant le menu le détail de mes compétences ainsi que mon parcours.
                    </p>
                    <div className="home__animation">
                        <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_xnlvopiy.json" background="transparent" speed="1" style={{width: '300px', height: '300px'}} loop  autoplay></lottie-player>
                    </div>   
                </div>
            </div>
        </div>
    )
};

export default Home;