import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import profilePic from "../../../Assets/images/profile-pic.png";
import "./style.scss";

function ItemMobile() {
    const menuItems = useSelector((state) => state.menuItems);

    const [isNavExpanded, setIsNavExpanded] = useState(false);

    return (
        <nav className="navigation">
            <img src={profilePic} alt="Timothé Bequet" className="menu__image"/>
            <p className="name">Timothé Bequet</p>
            <button className="hamburger" onClick={() => {setIsNavExpanded(!isNavExpanded);}}>
                {/* icon from heroicons.com */}
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white"
                >
                <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clipRule="evenodd"
                />
                </svg>
            </button>
            <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                <ul>
                {
                 menuItems.map((item) => (
                    <li key={item.text} className="nav-item">
                        <NavLink                            
                            className='nav-link'
                            to={item.link}
                            onClick={() => {setIsNavExpanded(false)}}
                        >
                            {item.text}
                        </NavLink>
                    </li>
                    ))
                }  
                    <li className="nav-item">
                        <a className="nav-link" href="https://www.linkedin.com/in/timothe-bequet/" target="_blank" rel="noreferrer">
                            Mon Profil LinkedIn
                        </a>
                    </li>                  
                </ul>
            </div>
        </nav>
    );
}

export default ItemMobile;