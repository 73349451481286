const resume = [
  {
    img: 'bi bi-person',
    text: ' Timothé Bequet',
    type: 'id',
  },
  {
    img: '',
    text: 'Développeur Front-End',
    type: 'poste',
  },
  {
    img: 'bi bi-geo-alt',
    text: ' 31200 Toulouse',
    type: 'ville',
  },
  {
    img: 'bi bi-telephone',
    text: ' 06.30.92.21.21',
    type: 'phone',
  },
  {
    img: 'bi bi-envelope-at',
    text: ' tim.bequet@gmail.com',
    type: 'mail',
  },  
];

export default resume;
