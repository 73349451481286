import { useSelector } from 'react-redux';
import './style.scss';
import Modal from './Modal/Modal';
import { Fragment } from 'react';

function Portfolio() {
    const portfolio = useSelector((state) => state.portfolio);
    return (
        <div className='portfolio principal'>
            <div className='section__container'>
                <h2 className="portfolio__title">Portfolio</h2>
                <div className="portfolio__animation">
                    <lottie-player src="https://assets9.lottiefiles.com/packages/lf20_j80rsr51.json"  background="transparent"  speed="1"  style={{width: '500px'}} loop  autoplay></lottie-player>
                </div>                 
                <div className="portfolio__container-div">
                    <div className="portfolio__composants-container">
                        <div className="portfolio__composants">
                            <h3 className="portfolio__header">Composants</h3>
                            <div className="portfolio-content">
                            {
                                portfolio.composants.map((composant) => (
                                    <Fragment key={composant.alt}>
                                        <div className={`portfolio-panel-content portfolio-img ${composant.imageClass}`} data-bs-toggle="modal" data-bs-target={composant.target}>
                                            <img src={composant.imagePath} alt={composant.alt} />
                                        </div>
                                        <Modal modal={composant.modal} />
                                    </Fragment>
                                ))
                            }
                            </div>
                        </div>
                    </div>
                    <div className="portfolio__integ-container">
                        <div className="portfolio__integ">
                            <h3 className="portfolio__header">Intégrations<br/>(exercices <a className="modal-link" href="https://www.frontendmentor.io/profile/TimotheBequet" target="_blank" rel="noopener noreferrer">Frontent Mentor <i className="bi bi-link-45deg"></i></a>)</h3>
                            <div className="portfolio-content">
                            {
                                portfolio.integrations.map((integration) => (
                                    <Fragment key={integration.alt}>
                                        <div className={`portfolio-panel-content portfolio-img ${integration.imageClass}`} data-bs-toggle="modal" data-bs-target={integration.target}>
                                            <img src={integration.imagePath} alt={integration.alt} />
                                        </div>
                                        <Modal modal={integration.modal} />
                                    </Fragment>
                                ))
                            }                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Portfolio;