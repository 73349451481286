import { useSelector } from 'react-redux';
import Languages from './Languages/Languages';
import './style.scss';

function Skills() {  
    const skills = useSelector((state) => state.skills);
    return (
        <div className="skills principal">
            <div className="section__container skills__container">
                <h2 className="skills__title">Compétences Techniques</h2>
                <div className="skills__animation">
                    <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_s2vaal7n.json"  background="transparent"  speed="1"  style={{width: '300px', height: '300px'}} loop  autoplay></lottie-player>
                </div>                
                <div className="skills__corps">
                {
                    skills.map((skill) => (
                        <Languages key={skill.type} skillArray={skill} />
                    ))
                }
                </div>
                <div className="skills__also">
                    <h4 className="skills__also-title">Mais aussi :</h4>
                    <div className="skills__also-item"><p><span className="skills__important"><i className="bi bi-pc-display-horizontal"></i>&nbsp; IDE : </span></p><p>JetBrains IntelliJ, Visual Studio Code, C++ Builder</p></div>
                    <div className="skills__also-item"><p><span className="skills__important"><i className="bi bi-git"></i>&nbsp; Gestionnaires de Sources : </span></p><p>Git, Dynamsoft Source AnyWhere</p></div>
                    <div className="skills__also-item"><p><span className="skills__important"><i className="bi bi-windows"></i>&nbsp; Environnements : </span></p><p>Windows, Linux</p></div>
                    <div className="skills__also-item"><p><span className="skills__important"><i className="bi bi-translate"></i>&nbsp; Langues : </span></p><p>Français (langue maternelle), Anglais (niveau C1)</p></div>
                    <div className="skills__also-item"><p><span className="skills__important"><i className="bi bi-search"></i>&nbsp; DYOR : </span></p><p>Niveau EXPERT (MDN, Google, StackOverflow, Developpez.com...)</p></div>
                    <h4 className="skills__also-title">Soft Skills :</h4>
                    <div className="skills__also-item-soft"><p><span className="skills__important"><i className="bi bi-forward"></i>&nbsp;</span></p><p>Envie d'apprendre</p></div>
                    <div className="skills__also-item-soft"><p><span className="skills__important"><i className="bi bi-forward"></i>&nbsp;</span></p><p>Curieux</p></div>
                    <div className="skills__also-item-soft"><p><span className="skills__important"><i className="bi bi-forward"></i>&nbsp;</span></p><p>Sociable</p></div>
                    <div className="skills__also-item-soft"><p><span className="skills__important"><i className="bi bi-forward"></i>&nbsp;</span></p><p>Optimiste</p></div>
                    <div className="skills__also-item-soft"><p><span className="skills__important"><i className="bi bi-forward"></i>&nbsp;</span></p><p>Pratique du badminton en club et en compétition depuis 1992</p></div>
                </div>
            </div>
        </div>
    )
};

export default Skills;
