const parcours = [
    {
        study: false,
        date: 'Depuis Juillet 2023',
        poste: 'Développeur Fullstack',
        posteSup: '',
        entreprise: 'CGI',
        entrepriseSup: '',
        linkEntreprise: 'https://www.cgi.com/france/fr-fr',      
        spe: '',
        content: [
            '- Missions de développement d\'applications web.',
            '- Technologies utilisées : Angular, VueJs, Java, SpringBoot.',
        ],
    },    
    {
        study: false,
        date: 'Février 2023 - Mars 2023',
        poste: 'Développeur Front-End React',
        posteSup: '',
        entreprise: 'Ecole O\'Clock',
        entrepriseSup: '',
        linkEntreprise: 'https://oclock.io/',      
        spe: '',
        content: [
            '- Développement Front-End d\'une application web en un mois (méthodologie Scrum, en 4 sprints).',
            '- Application réalisée en équipe (3 développeurs Front-End, 3 développeurs Back-End), de sa conception jusqu\'au déploiement d\'un Minimium Viable Product.',
        ],
    },
    {
        study: true,
        date: 'Septembre 2022 - Mars 2023',
        poste: 'Formation de Développeur Web et Web Mobile',
        posteSup: 'Titre pro. RNCP niveau 5 (équivalent Bac+2)',
        entreprise: 'Ecole O\'Clock',
        entrepriseSup: '',
        linkEntreprise: 'https://oclock.io/',
        spe: 'Spécialisation React.js',
        content: [
            '798 h intensives',
            '- 3 mois de Socle : HTML5 / CSS3 / PHP / Javascript',
            '- 1 mois de spécialisation en React',
            '- 1 mois de projet en équipe',
        ],
    },
    {
        study: false,
        date: 'Mai 2012 - Septembre 2022',
        poste: 'Analyste Développeur C++',
        posteSup: '',
        entreprise: 'Technic-Soft',
        entrepriseSup: ', Toulouse',
        linkEntreprise: 'https://www.technic-soft.fr/',      
        spe: '',
        content: [
            '- Développement d\'un logiciel de gestion du SAV en C++ au sein d’une équipe de 4 développeurs (conception, tests, corrections, optimisations)',
            '- Technologies utilisées : C++, SQL',
        ],
    },  
    {
        study: true,
        date: 'Septembre 2009 - Mai 2012',
        poste: 'DUT Informatique',
        posteSup: 'en cours du soir',
        entreprise: 'IUT Paul Sabatier',
        entrepriseSup: ', Toulouse',
        linkEntreprise: '',      
        spe: '',
        content: [
            'Eléments et notions vus durant cette formation :',
            'Algorithmie, SQL, C++, Java, WinDev, MCD',
        ],
    }, 
    {
        study: false,
        date: 'Mars 2010 - Mai 2012',
        poste: 'Employé de Service',
        posteSup: 'en cours du soir',
        entreprise: 'Sodexo',
        entrepriseSup: ', Airbus Central Entity, Blagnac',
        linkEntreprise: '',      
        spe: '',
        content: [
            '- En parallèle des cours du soir, remplissage et maintenance des distributeurs de café au sein du siège social d\'Airbus à Blagnac.',
            '- Gestion en autonomie d\'un parc de distributeurs.',
        ],
    },   
    {
        study: true,
        date: 'Septembre 2006 - Juillet 2007',
        poste: 'Licence Pro. Gestion de Projets Urbains',
        posteSup: '',
        entreprise: 'IUT d’Aix-en-Provence',
        entrepriseSup: '',
        linkEntreprise: '',      
        spe: '',
        content: [
            'Apprentissage de la gestion de projets dans le domaine de l\'urbanisme.',
        ],
    }, 
    {
        study: false,
        date: 'Eté 2007',
        poste: 'Chargé d\'études',
        posteSup: 'stagiaire',
        entreprise: 'Office Municipal des Sports',
        entrepriseSup: 'd\'Aix-en-Provence',
        linkEntreprise: '',      
        spe: '',
        content: [
            'Réalisation d\'un état des lieux des installations sportives dans l\'aire urbaine aixoise, en vue d\'un projet d\'uniformisation de l\'accès au sport dans la communauté d\'agglomération d\'Aix-en-Provence',
        ],
    }, 
    {
        study: false,
        date: 'Hiver 2006-2007',
        poste: 'Chargé d\'études',
        posteSup: 'stagiaire',
        entreprise: 'Mairie de La Ciotat',
        entrepriseSup: '',
        linkEntreprise: '',      
        spe: '',
        content: [
            'Réalisation d\'un état des lieux des équipements culturels de la ville de La Ciotat, en vue de la création d\'un centre culturel.',
        ],
    },     
    {
        study: true,
        date: 'Septembre 2004 - Juillet 2006',
        poste: 'DEUG Géographie et Aménagement',
        posteSup: '',
        entreprise: 'Université Toulouse Jean Jaurès',
        entrepriseSup: '',
        linkEntreprise: '',      
        spe: '',
        content: [],
    },    
    {
        study: true,
        date: 'Juin 2003',
        poste: 'Baccalauréat série ES',
        posteSup: '',
        entreprise: 'Lycée Polyvalent Rive Gauche',
        entrepriseSup: ', Toulouse',
        linkEntreprise: '',      
        spe: '',
        content: [],
    },                 
];

export default parcours;