import { Fragment, useState } from "react";
import './style.scss';

function Modal({ modal }) {
    const [isHover, setIsHover] = useState(false);
    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    return (
        <div className="modal fade" id={modal.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content text-bg-dark">
                    <div className="modal-header">
                        {
                            modal.title !== '' ?
                            <h1 className="modal-title fs-5" id="exampleModalLabel">{modal.title}</h1>
                            : undefined
                        }
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="portfolio-panel">
                            <div className="panel-heading">
                                <h4 className="panel-languages">{modal.languages}</h4>
                            </div>
                            <div className="panel-content">
                            {
                                modal.content.map((contenu) => (
                                    <p key={contenu}>{contenu}</p>
                                ))
                            }
                            {
                                modal.sources.map((source) => (
                                    <Fragment key={source.title}>
                                        <a className="modal-link" href={source.href} target="_blank" rel="noopener noreferrer"><i className="bi bi-link-45deg"></i> {source.title}</a><br/>
                                    </Fragment>
                                ))
                            }
                                <div 
                                    className="modal-img-container"
                                    onMouseEnter={modal.imageHover !== null ? handleMouseEnter : null}
                                    onMouseLeave={modal.imageHover !== null ? handleMouseLeave : null}                                    
                                >
                                    {
                                        modal.imageHover === null ?
                                        <img 
                                            className="modal-img" 
                                            src={modal.image} 
                                            alt=""
                                        />
                                        : <div 
                                            className={`img-container ${isHover ? 'img-container-hover' : ''}`}
                                            style={{                                            
                                                background: isHover ? `url(${modal.imageHover})` : `url(${modal.image})`,
                                            }}></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;